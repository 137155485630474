<article class="service-type-card h-100 position-relative" [class.active]="isActive">
  <div class="service-type-card-header d-flex align-items-start justify-content-end flex-column h-100">
    <h2 class="service-type-title heading-md">
      {{ service.title }}
    </h2>
    <div class="btn-wrapper">
      <button class="btn p-0" (click)="toggleDescription()">Научи повече &#8594;</button>
    </div>
  </div>
  <div class="service-type-card-content" #serviceTypeCardContentRef>
    <span class="service-type-close-button bi bi-x" (click)="toggleDescription()"></span>
    <div class="service-type-card-text">
      <ng-container *ngFor="let description of service.description; let last = last">
        <p class="text-lg text-dark-gray" [class.mb-0]="last" *ngIf="description.sentence">
          {{ description.sentence }}
        </p>
        <div *ngIf="description.list">
          <ul class="text-lg text-dark-gray my-4" *ngFor="let list of description.list">
            <li>{{ list }}</li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</article>
