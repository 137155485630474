import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ServiceTypeDetails} from './service-type-info.type';

@Component({
  selector: 'kbg-service-type',
  templateUrl: './service-type.component.html',
  styleUrls: ['./service-type.component.scss']
})
export class ServiceTypeComponent implements OnInit {
  @Input()
  service: ServiceTypeDetails;
  @ViewChild('serviceTypeCardContentRef')
  serviceTypeCardContent: ElementRef;
  isActive = false;
  constructor() {}

  ngOnInit(): void {}

  toggleDescription(): void {
    this.isActive = !this.isActive;
    this.serviceTypeCardContent.nativeElement.scrollTop = 0;
  }
}
