import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {HomePageComponent} from './home-page/home-page.component';
import {LayoutModule} from './layout/layout.module';
import {HeaderImageSectionComponent} from './home-page/header-image-section/header-image-section.component';
import {AboutUsSectionComponent} from './home-page/about-us-section/about-us-section.component';
import {ServicesSectionComponent} from './home-page/services-section/services-section.component';
import {ContactsSectionComponent} from './home-page/contacts-section/contacts-section.component';
import {ServiceTypeComponent} from './home-page/services-section/service-type/service-type.component';
import {ScrollSpyDirective} from './scroll-spy/scroll-spy.directive';
import { GoogleMapsComponent } from './home-page/contacts-section/google-maps/google-maps.component';
import { ContactsInfoComponent } from './home-page/contacts-section/contacts-info/contacts-info.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    HeaderImageSectionComponent,
    AboutUsSectionComponent,
    ServicesSectionComponent,
    ServiceTypeComponent,
    ContactsSectionComponent,
    ScrollSpyDirective,
    GoogleMapsComponent,
    ContactsInfoComponent
  ],
  imports: [
    BrowserModule,
    LayoutModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
