import {Component} from '@angular/core';
import {serviceTypeInfo} from './service-type/service-type-info.const';

@Component({
  selector: 'kbg-services-section',
  templateUrl: './services-section.component.html',
  styleUrls: ['./services-section.component.scss']
})
export class ServicesSectionComponent {
  serviceTypeInfo = serviceTypeInfo;
}
