<header
  class="header align-items-center"
  [ngClass]="{
    active: isVisible,
    'sticky-header': !isScrollTop,
    'smaller-sticky-header': mobileNavigationService.navigationVisibility || !this.isScrollTop
  }"
>
  <div class="container h-100">
    <div class="row g-0 justify-content-between align-items-center h-100">
      <div class="col-12 h-100">
        <div class="d-flex px-lg-0 align-items-center h-100 justify-content-between justify-content-md-start">
          <h1 class="mb-0 cursor-pointer" (click)="scrollToElementAndCloseMobileNav(HomePageSection.KBG_HEADER_IMAGE_SECTION)">
            <a class="header-logo d-flex align-items-center">
              <img class="logo" src="assets/icons/kontrol_cyrilic_stack_white.svg" alt="" />
            </a>
          </h1>
          <div class="d-inline-block d-md-none">
            <button
              class="btn hamburger-menu-btn cursor-pointer p-0 line-height-1"
              [class.active]="mobileNavigationService.navigationVisibility"
              (click)="mobileNavigationService.navigationVisibility = !mobileNavigationService.navigationVisibility"
            >
              <span class="hamburger-menu"></span>
            </button>
          </div>
          <nav class="nav d-none d-md-flex">
            <a
              class="nav-link cursor-pointer"
              [class.active]="currentSection === HomePageSection.KBG_ABOUT_US_SECTION"
              (click)="scrollToElement(HomePageSection.KBG_ABOUT_US_SECTION)"
            >
              За нас
            </a>
            <a
              class="nav-link cursor-pointer"
              [class.active]="currentSection === HomePageSection.KBG_SERVICES_SECTION"
              (click)="scrollToElement(HomePageSection.KBG_SERVICES_SECTION)"
            >
              Услуги
            </a>
            <a
              class="nav-link cursor-pointer"
              [class.active]="currentSection === HomePageSection.KBG_CONTACTS_SECTION"
              (click)="scrollToElement(HomePageSection.KBG_CONTACTS_SECTION)"
            >
              Контакти
            </a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</header>

<div
  class="curtain"
  [ngClass]="{active: mobileNavigationService.navigationVisibility}"
  (click)="mobileNavigationService.navigationVisibility = false"
></div>
<nav class="side-nav" [class.active]="mobileNavigationService.navigationVisibility">
  <div class="row">
    <div class="col-12">
      <div class="nav flex-column text-center p-0">
        <a
          class="nav-link text-lg font-weight-600"
          [class.active]="currentSection === HomePageSection.KBG_ABOUT_US_SECTION"
          (click)="scrollToElementAndCloseMobileNav(HomePageSection.KBG_ABOUT_US_SECTION)"
        >
          <span class="align-middle">За нас</span>
        </a>
        <a
          class="nav-link text-lg font-weight-600"
          [class.active]="currentSection === HomePageSection.KBG_SERVICES_SECTION"
          (click)="scrollToElementAndCloseMobileNav(HomePageSection.KBG_SERVICES_SECTION)"
        >
          <span class="align-middle">Услуги</span>
        </a>
        <a
          class="nav-link text-lg font-weight-600"
          [class.active]="currentSection === HomePageSection.KBG_CONTACTS_SECTION"
          (click)="scrollToElementAndCloseMobileNav(HomePageSection.KBG_CONTACTS_SECTION)"
        >
          <span class="align-middle">Контакти</span>
        </a>
      </div>
    </div>
  </div>
</nav>
