import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {MobileNavigationService} from '../mobile-navigation.service';
import {ViewportScroller} from '@angular/common';
import {fromEvent, Subscription} from 'rxjs';
import {ScrollSpyService} from '../../scroll-spy/scroll-spy.service';
import {map, pairwise, share, tap, throttleTime} from 'rxjs/operators';
import {HomePageSection} from '../../home-page/home-page-section.enum';

@Component({
  selector: 'kbg-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  isVisible = true;
  isScrollTop = true;
  currentSection: HomePageSection;
  HomePageSection = HomePageSection;
  private subscriptions = new Subscription();

  constructor(
    private scrollSpyService: ScrollSpyService,
    private scroller: ViewportScroller,
    public mobileNavigationService: MobileNavigationService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.scrollSpyService.currentElement$.subscribe(elementId => {
        this.currentSection = elementId as HomePageSection;
      })
    );
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      fromEvent(window, 'scroll')
        .pipe(
          throttleTime(10),
          map(() => window.pageYOffset),
          pairwise(),
          tap(([y1, y2]) => {
            this.isScrollTop = !y2 || y2 <= 500;
          }),
          share()
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  scrollToElement(elementId: HomePageSection): void {
    this.scroller.scrollToAnchor(elementId);
  }

  scrollToElementAndCloseMobileNav(elementId: HomePageSection): void {
    if (this.mobileNavigationService.navigationVisibility) {
      this.mobileNavigationService.navigationVisibility = false;
    }
    this.scrollToElement(elementId);
  }
}
