<article class="services-section">
  <div class="container container-padding-lg">
    <div class="row">
      <div class="col-12">
        <h1 class="heading-xl mb-5 heading-with-line">Какво предлагаме</h1>
      </div>
    </div>
    <div class="row position-relative">
      <div class="col-12 col-md-12 col-lg-6 mb-4 service-type-col" *ngFor="let service of serviceTypeInfo | keyvalue">
        <kbg-service-type [service]="service.value"></kbg-service-type>
      </div>
    </div>
  </div>
</article>
