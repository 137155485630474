<section class="header-image-section header-image-bg">
  <div class="container">
    <div class="row">
      <div class="col-10 col-md-9 col-lg-8 col-xxl-7">
        <div class="header-image-content-wrapper">
          <h1 class="header-image-content-heading text-white mb-0">Всичко, което трябва да знаете за строителството</h1>
        </div>
      </div>
    </div>
  </div>
</section>
