import {Directive, Input, EventEmitter, Output, ElementRef, OnInit, OnDestroy} from '@angular/core';
import {ScrollSpyService} from './scroll-spy.service';
import {fromEvent, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Directive({
  selector: '[kbgScrollSpy]',
  providers: [ScrollSpyService]
})
export class ScrollSpyDirective implements OnInit, OnDestroy {
  @Input() public spiedClass = '';
  @Output() public sectionChange = new EventEmitter<string>();
  private currentElementId: string;
  private subscription: Subscription;

  constructor(private el: ElementRef, private scrollSpyService: ScrollSpyService) {}

  ngOnInit(): void {
    this.subscription = fromEvent(window, 'scroll')
      .pipe(debounceTime(30))
      .subscribe((event: any) => {
        let currentElementId: string;
        const nodes = this.el.nativeElement.querySelectorAll('.scroll-element');
        const scrollTop = window.scrollY;
        const parentOffset = window.pageYOffset;

        nodes.forEach(node => {
          if (node.offsetTop - parentOffset <= scrollTop - 600) {
            currentElementId = node.id;
          }
        });

        if (currentElementId !== this.currentElementId) {
          this.currentElementId = currentElementId;
          this.sectionChange.emit(this.currentElementId);
          this.scrollSpyService.inViewElement = currentElementId;
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
