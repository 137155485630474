import {OfficeCoordinates} from './office-coordinates.interface';
import {environment} from '../../../../environments/environment';

const officesCoordinates: OfficeCoordinates[] = [
  {
    address: 'ул. „Хайдушка“ 6, 4000 Каменица 1, Пловдив',
    coordinates: {lat: 42.13808597337188, lng: 24.753395456352685}
  },
  {
    address: 'бул. „Санкт Петербург“ 33А, 4017 Каменица 2, Пловдив',
    coordinates: {lat: 42.136113767716665, lng: 24.764574906659444}
  }
];

const markerConfig = {
  title: 'КОНТРОЛ.БГ2603 ЕООД',
  generatePopupMarkup: (companyName: string, companyAddress: string): string => {
    return `<div>
        <h3 class="mb-0 text-lg">${companyName}</h3>
        <p class="mb-0 text-md">${companyAddress}</p>
      </div>`;
  }
};

export const googleMapsConfig = {
  apiKey: environment.googleMapsApiKey,
  language: 'bg',
  region: 'BG',
  center: officesCoordinates[0].coordinates,
  zoom: 14,
  mapTypeControl: false,
  officesCoordinates,
  markerConfig
};
