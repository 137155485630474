<article class="contacts-section">
  <div class="container container-padding-lg">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-4">
        <kbg-contacts-info></kbg-contacts-info>
      </div>
      <div class="col-12 col-md-12 col-lg-8">
        <kbg-google-maps></kbg-google-maps>
      </div>
    </div>
  </div>
</article>
