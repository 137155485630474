import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Loader} from '@googlemaps/js-api-loader';
import {OfficeCoordinates} from './office-coordinates.interface';
import {googleMapsConfig} from './google-maps-config.const';

@Component({
  selector: 'kbg-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements OnInit, OnDestroy {
  @ViewChild('mapsPlaceholderRef')
  mapsPlaceholder: ElementRef;

  private googleMapsInstance: google.maps.Map;
  private googleMapsEvents: google.maps.MapsEventListener[] = [];
  private openedMarker: any;
  constructor() {}

  ngOnInit(): void {
    const loader = new Loader({
      apiKey: googleMapsConfig.apiKey,
      language: googleMapsConfig.language,
      region: googleMapsConfig.region
    });

    loader.load().then(() => {
      this.googleMapsInstance = new google.maps.Map(this.mapsPlaceholder.nativeElement, {
        center: googleMapsConfig.center,
        zoom: googleMapsConfig.zoom,
        mapTypeControl: googleMapsConfig.mapTypeControl
      });
      googleMapsConfig.officesCoordinates.forEach(officeCoordinates => this.createMarkerWithInfo(officeCoordinates));
    });
  }

  ngOnDestroy(): void {
    this.googleMapsEvents.forEach(event => google.maps.event.removeListener(event));
  }

  private createMarkerWithInfo(officeCoordinates: OfficeCoordinates): void {
    const marker = new google.maps.Marker({
      position: officeCoordinates.coordinates,
      map: this.googleMapsInstance,
      title: googleMapsConfig.markerConfig.title
    });

    const markerInfoPopup = new google.maps.InfoWindow({
      content: googleMapsConfig.markerConfig.generatePopupMarkup(
        googleMapsConfig.markerConfig.title,
        officeCoordinates.address
      )
    });

    this.googleMapsEvents.push(
      marker.addListener('click', () => {
        markerInfoPopup.open({
          anchor: marker,
          map: this.googleMapsInstance,
          shouldFocus: false
        });

        if (this.openedMarker && this.openedMarker.content !== (markerInfoPopup as any).content) {
          this.openedMarker.close();
        }
        this.openedMarker = markerInfoPopup;
      })
    );
  }
}
