import {ServiceType} from './service-type.enum';
import {ServiceTypeInfo} from './service-type-info.type';

export const serviceTypeInfo: ServiceTypeInfo = {
  [ServiceType.INVESTMENT_CONTROL]: {
    title: 'Инвеститорски контрол',
    description: [
      {
        sentence:
          'Новото хилядолетие даде възможност в строителната индустрия да се създадат множество фирми, осъществяващи разнообразни строително-монтажни работи. Те предлагат различно съотношение качество и цена. Ние можем да Ви помогнем в избора на най-правилните изпълнители, които да реализират Вашият проект при най-добрите за Вас условия и да отгоорят на всичките Ви изисквания. Нашият екип от специалисти може да управлява изграждането на Вашата инвестиция независимо от нейните мащаби. Ние ще гарантираме успешното завършване на Вашият апартамент, еднофамилна къща, хотел, болница, склад друг обект. Не се колебайте да се свържете с нас и да получите оферта!'
      }
    ]
  },
  [ServiceType.CONSULTING_ACTIVITY]: {
    title: 'Консултиране при закупуване на жилище',
    description: [
      {
        sentence:
          'През последните години в Пловдив се осъществи истински „бум“ в строителството на нови многофамилни жилищни сгради. Реализирани са и няколко комплекса от еднофамилни жилищни сгради. Всички те са изградени с различно качество и различен клас материали. Ние можем да Ви предоставим експертно мнение при огледа на избраното от Вас жилище, с което да ви дадем сигурност, че реално ще получите, това, което желаете и очаквате. От нас може да получите актуален списък с новостроящите се жилищни сгради в избран от Вас район и директен контакт с продавача, както и правна консултация с асистенция за сключването на договор за покупко-продажба и реалното реализиране на сделката пред нотариус. За всякакви въпроси свържете се с нас, ние с радост ще отговорим!'
      }
    ]
  },
  [ServiceType.REPAIR_ACTIVITY]: {
    title: 'Довършителни ремонтни дейности',
    description: [
      {
        sentence:
          'КОНТРОЛ.БГ2603 ЕООД разполага с квалифициран персонал, който да изпълни следните довършителни строително-монтажни работи за Вас:'
      },
      {list: ['Настилки и облицовки', 'Сухо строителство', 'Шпакловки и боя', 'Монтаж на топлоизолационна система']},
      {
        sentence:
          'За правилното енергийно ефективно функциониране на сградата едно от най-важните звена е топлоизолационната система. Основният ключов елемент е изолационният материал. Ние работим с бял EPS, графитен EPS и фасадна вата. От нашия инженерно-технически персонал ще получите безпристрастна консултация за най-подходящата за Вас топлоизолационна система. Ние работим с водещите световни производители на продукти за топлоизолация. Предлагаме реални срокове за изпълнение, гаранция и следгаранционно обслужване. Запишете си безплатен оглед и консултация!'
      }
    ]
  },
  [ServiceType.PROPERTIES_COMPENSATION]: {
    title: 'Парцели срещу обезщетение',
    description: [
      {
        sentence:
          'КОНТРОЛ.БГ2603 ЕООД търси парцели за закупуване или срещу обезщетение. Ние предлагаме добри условия, качествено строителство с материали на утвърдени международни производители и бърза реализация. От нашия екип от специалисти ще получите коректно отношение и гарантирана удовлетвореност от сделката. Не се колебайте да се свържете с нас и да получите оферта!'
      }
    ]
  },
  [ServiceType.MATERIALS_SUPPLY]: {
    title: 'Доставка на строителни материали',
    description: [
      {
        sentence:
          'КОНТРОЛ.БГ2603 ЕООД е тясно специализирана фирма в областта на инвеститорския надзор. Ние ще проучим и ще ви предложим най-добрата оферта за необходимите Ви строителни материали.'
      },
      {
        sentence:
          'КОНТРОЛ.БГ2603 ЕООД ще проследи цялостния процес от одобрение и приемане на офертата, през заявката и доставката до обекта на всички необходими материали. Ние ще осигурим най-добрите условия, съобразени със спецификата и бюджета на вашият проект. Ще следим за съответствието между поръчаните и доставените продукти, като по този начин максимално гарантираме сигурността на вашето капиталовложение. Свържете се с нас и си запазете час за консултация със специалист.'
      }
    ]
  },
  [ServiceType.RECOMMENDED_PROPERTY]: {
    title: 'Препоръчани обекти',
    description: [
      {
        sentence:
          'КОНТРОЛ.БГ2603 ЕООД е фирма, тясно специализирана в консултантски услуги. Задачата ни е да проследим цялостния строително-инвестиционен процес и да ви осигурим качествено изпълнение и материали. За нас е важно да отговорим на очакванията на всеки възложител по отношение качество- цена, затова нашият квалифициран екип от специалисти ще Ви помогне да преминете успешно през това предизвикателство и да се насладите на мечтания апартамент, къща, офис или друг обект. Ние ще контролираме взаимовръзките между контрагентите и правилната експлоатация на вложените от Вас финансови ресурси. При желание от Ваша страна можем да извършим всички довършителни строително-монтажни работи, с което ви осигуряваме скъсяване на веригата и спестяваме усилията да подбирате вие изпълнители, а за сметка на това ви даваме гаранция на изпълнението. Не се колебайте и ни потърсете! С контрол и постоянство ще изградим доверие в нас, а Вие ще получите спокойствие и сигурност.'
      }
    ]
  }
};
