<div class="row">
  <div class="col-12">
    <h1 class="heading-xl mb-5 heading-with-line">Контакти</h1>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <address>
      <div class="row">
        <div class="col-12">
          <div class="mb-4">
            <div class="mb-1">
              <i class="bi bi-geo-alt-fill align-middle me-1 text-md"></i>
              <span class="align-middle text-lg font-weight-600">Адрес</span>
            </div>
            <div class="text-sm text-black">ул. „Хайдушка“, 4000 Каменица 1, Пловдив</div>
            <div class="text-sm text-black">бул. „Санкт Петербург“ 33А, 4017 Каменица 2, Пловдив</div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-12">
          <div class="mb-4">
            <div class="mb-1">
              <i class="bi bi-telephone-fill align-middle me-1 text-md"></i>
              <span class="align-middle text-lg font-weight-600">Телефон</span>
            </div>
            <div class="text-sm text-black">
              <a href="tel:0882488814">0882488814</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-12">
          <div class="mb-4">
            <div class="mb-1">
              <i class="bi bi-envelope-fill align-middle me-1 text-md"></i>
              <span class="align-middle text-lg font-weight-600">Имейл</span>
            </div>
            <div class="mb-4 text-sm text-black">
              <a href="mailto:office@kontrolbg.com">office@kontrolbg.com</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-12">
          <div class="mb-4">
            <div class="mb-1">
              <i class="bi bi-envelope-fill align-middle me-1 text-md"></i>
              <span class="align-middle text-lg font-weight-600">Имейл</span>
            </div>
            <div class="mb-4 text-sm text-black">
              <a href="mailto:office@kontrolbg.com">office@kontrolbg.com</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-12">
          <div class="mb-4">
            <div class="mb-1">
              <i class="bi bi-facebook align-middle me-1 text-md"></i>
              <span class="align-middle text-lg font-weight-600">Facebook</span>
            </div>
            <div class="mb-4 text-sm text-black">
              <a href="https://www.facebook.com/%D0%9A%D0%BE%D0%BD%D1%82%D1%80%D0%BE%D0%BB%D0%B1%D0%B3-106512014830259">
                Посетете ни във Facebook
              </a>
            </div>
          </div>
        </div>
      </div>
    </address>
  </div>
</div>
