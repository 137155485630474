import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class ScrollSpyService {
  private inViewElement$ = new Subject<string>();
  currentElement$ = this.inViewElement$.asObservable();

  set inViewElement(elementId: string) {
    this.inViewElement$.next(elementId);
  }
}
