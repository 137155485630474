import {Component} from '@angular/core';
import {HomePageSection} from './home-page-section.enum';

@Component({
  selector: 'kbg-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {
  HomePageSection = HomePageSection;
}
