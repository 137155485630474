<article class="about-us-section">
  <div class="container container-padding-lg">
    <div class="row">
      <div class="col-12">
        <h1 class="heading-xl mb-5 heading-with-line">Кои сме ние</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="text-lg">
          КОНТРОЛ.БГ2603 ЕООД е фирма, тясно специализирана в консултантски услуги. Задачата ни е да проследим цялостния
          строително-инвестиционен процес и да ви осигурим качествено изпълнение и материали. За нас е важно да
          отговорим на очакванията на всеки възложител по отношение качество-цена, затова нашият квалифициран екип от
          специалисти ще Ви помогне да преминете успешно през това предизвикателство и да се насладите на мечтания
          апартамент, къща, офис или друг обект. Ние ще контролираме взаимовръзките между контрагентите и правилната
          експлоатация на вложените от Вас финансови ресурси. При желание от Ваша страна можем да извършим всички
          довършителни строително-монтажни работи, с което ви осигуряваме скъсяване на веригата и спестяваме усилията да
          подбирате вие изпълнители, а за сметка на това ви даваме гаранция на изпълнението. Не се колебайте и ни
          потърсете! С контрол и постоянство ще изградим доверие в нас, а Вие ще получите спокойствие и сигурност.
        </p>
      </div>
    </div>
  </div>
</article>
